import React from 'react';
import styled, { keyframes } from "styled-components";

import { headerAnimationDuration } from "@utils/variables";
import { letterPathLength } from "@utils/keyframes";

const letter = keyframes`
  0% { transform: scale(0) rotate(35deg); }
  3% { transform: scale(0) rotate(35deg); }
  12.2% { transform: none; }
  66% { transform: none; }
  72.2% { transform: scale(0) rotate(35deg); }
  100% { transform: scale(0) rotate(35deg); }
`;

const scale = keyframes`
  0% { transform: scale(0); }
  3% { transform: scale(0); }
  12.2% { transform: scale(1); }
  66% { transform: scale(1); }
  72.2% { transform: scale(0); }
  100% { transform: scale(0); }
`;

const StyledLetter = styled.g`
  transform: scale(0) rotate(35deg);
  transform-origin: 25px 30px;
  animation: ${letter} ${headerAnimationDuration}s 2.3s infinite;
`;

const StyledShape = styled.path<{ animationDelay: number }>`
  transform: scale(0);
  animation: ${scale} ${headerAnimationDuration}s infinite;
  animation-delay: ${({ animationDelay }) => `${animationDelay}s` };
`;

const StyledLine = styled.path<{ animateShort?: boolean, animationDelay: number }>`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${headerAnimationDuration}s infinite;
  animation-name: ${({ animateShort }) => letterPathLength(animateShort ? 994 : 960) };
  animation-delay: ${({ animationDelay }) => `${animationDelay}s` };
`;

const LetterRight = () => (
  <svg viewBox="0 0 57 41" preserveAspectRatio="xMidYMid slice">
    <g transform="matrix(1,0,0,1,23.12700080871582,22.179000854492188)">
      <StyledShape animationDelay={ 2.4 } fill="#f5f6fa" fillOpacity="1" d=" M7.388000011444092,-13.54800033569336 C-0.722000002861023,-14.777999877929688 0.5379999876022339,-7.98799991607666 -4.2820000648498535,-3.3480000495910645 C-7.861999988555908,0.10199999809265137 -10.482000350952148,0.722000002861023 -11.67199993133545,3.611999988555908 C-14.732000350952148,11.092000007629395 -9.041999816894531,13.802000045776367 -1.2020000219345093,13.692000389099121 C6.638000011444092,13.592000007629395 14.868000030517578,10.232000350952148 11.887999534606934,2.371999979019165 C8.697999954223633,-6.047999858856201 11.407999992370605,-1.6779999732971191 12.178000450134277,-8.23799991607666 C12.708000183105469,-12.718000411987305 9.29800033569336,-13.267999649047852 7.388000011444092,-13.54800033569336" />
    </g>
    <StyledLetter>
      <g transform="matrix(1,0,0,1,13.355999946594238,17.865999221801758)">
        <path fill="#f49386" fillOpacity="1" d=" M-6.671000003814697,-5.52400016784668 C-6.671000003814697,-5.52400016784668 -8.121999740600586,2.0460000038146973 -8.121999740600586,2.0460000038146973 C-8.121999740600586,2.0460000038146973 6.4079999923706055,5.526000022888184 6.4079999923706055,5.526000022888184 C6.4079999923706055,5.526000022888184 8.118000030517578,-0.6940000057220459 8.118000030517578,-0.6940000057220459 C8.118000030517578,-0.6940000057220459 -6.671000003814697,-5.52400016784668 -6.671000003814697,-5.52400016784668z" />
      </g>
      <g transform="matrix(1,0,0,1,14.081000328063965,15.86400032043457)">
        <path strokeLinejoin="round" fillOpacity="0" stroke="#761113" strokeOpacity="1" strokeWidth="0.1" d=" M-7.395999908447266,-3.5220000743865967 C-7.395999908447266,-3.5220000743865967 -0.7269999980926514,3.5179998874664307 -0.7269999980926514,3.5179998874664307 C-0.7269999980926514,3.5179998874664307 7.39300012588501,1.3079999685287476 7.39300012588501,1.3079999685287476" />
      </g>
      <g transform="matrix(1,0,0,1,8.503999710083008,18.812999725341797)">
        <path strokeLinejoin="round" fillOpacity="0" stroke="#761113" strokeOpacity="1" strokeWidth="0.1" d=" M-3.2699999809265137,1.0950000286102295 C-3.2699999809265137,1.0950000286102295 3.2699999809265137,-1.0950000286102295 3.2699999809265137,-1.0950000286102295" />
      </g>
      <g transform="matrix(1,0,0,1,17.711999893188477,21.101999282836914)">
        <path strokeLinejoin="round" fillOpacity="0" stroke="#761113" strokeOpacity="1" strokeWidth="0.1" d=" M-2.052999973297119,-2.2880001068115234 C-2.052999973297119,-2.2880001068115234 2.056999921798706,2.2920000553131104 2.056999921798706,2.2920000553131104" />
      </g>
    </StyledLetter>
    <g transform="matrix(1,0,0,1,15.456999778747559,7.293000221252441)">
      <StyledLine animationDelay={ 2 } strokeLinejoin="round" fillOpacity="0" stroke="#761113" strokeOpacity="1" strokeWidth="0.1" d=" M-15.41100025177002,-7.177000045776367 C-4.281000137329102,-2.756999969482422 6.050000190734863,2.062999963760376 15.40999984741211,7.172999858856201" />
    </g>
    <g transform="matrix(1,0,0,1,5.546000003814697,7.394999980926514)">
      <StyledShape animationDelay={ 2.6 } fill="#ed3e2e" fillOpacity="1" d=" M-3.61299991607666,-2.1579999923706055 C-3.61299991607666,-2.1579999923706055 1.2070000171661377,2.3420000076293945 1.2070000171661377,2.3420000076293945 C1.2070000171661377,2.3420000076293945 3.617000102996826,-2.3380000591278076 3.617000102996826,-2.3380000591278076 C3.617000102996826,-2.3380000591278076 -3.61299991607666,-2.1579999923706055 -3.61299991607666,-2.1579999923706055z" />
    </g>
    <g transform="matrix(1,0,0,1,7.823999881744385,8.038999557495117)">
      <StyledLine animateShort animationDelay={ 2.5 } strokeLinejoin="round" fillOpacity="0" stroke="#761113" strokeOpacity="1" strokeWidth="0.1" d=" M2.249000072479248,0.8610000014305115 C2.249000072479248,0.8610000014305115 -2.250999927520752,-0.859000027179718 -2.250999927520752,-0.859000027179718" />
    </g>
    <g transform="matrix(1,0,0,1,9.680000305175781,7.125)">
      <StyledLine animateShort animationDelay={ 2.5 } strokeLinejoin="round" fillOpacity="0" stroke="#761113" strokeOpacity="1" strokeWidth="0.1" d=" M2.7079999446868896,0.9169999957084656 C2.7079999446868896,0.9169999957084656 -2.7119998931884766,-0.9129999876022339 -2.7119998931884766,-0.9129999876022339" />
    </g>
    <g transform="matrix(1,0,0,1,30.43400001525879,19.386999130249023)">
      <StyledLine animationDelay={ 2 } strokeLinejoin="round" fillOpacity="0" stroke="#761113" strokeOpacity="1" strokeWidth="0.1" d=" M-6.438000202178955,0.0010000000474974513 C-2.187999963760376,0.8410000205039978 8.881999969482422,4.921000003814697 8.881999969482422,4.921000003814697 C3.361999988555908,1.5210000276565552 -2.568000078201294,-1.7690000534057617 -8.878000259399414,-4.919000148773193" />
    </g>
  </svg>
);

export default LetterRight;
