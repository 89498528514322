import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { down } from "styled-breakpoints";
import styled from "styled-components";
import { motion } from "framer-motion";
import Img from "gatsby-image";
import tw from "twin.macro";

import LetterRight from "@components/svg/letter-right";
import LetterLeft from "@components/svg/letter-left";

const ImagesWrapper = tw.div`
  relative w-full max-w-340 mx-auto pr-15
  sm:ml-50 sm:mr-auto
  md:max-w-300 md:h-290 md:m-0 md:p-0
  lg:max-w-full 
  xl:h-400
`;

const ImageIllustration = tw(Img)`
  absolute! hidden z-1
  md:block md:top-10 md:left-100 md:w-300
  lg:top-700 lg:-left-245
  xl:-left-285 xl:w-478
  xxl:top-685 xxl:-left-320 xxl:w-580
`;

const ImageMain = tw(Img)`
  w-full
  md:absolute! md:-top-25 md:left-300 md:w-400
  lg:top-675 lg:left-auto lg:right-155
  xl:right-205 xl:w-560
  xxl:right-60 xxl:w-660
`;

const LeftLetter = tw.div`
  absolute top-85 -left-5 w-120 z-1
  md:top-90 md:left-300 md:w-150
  lg:top-75 lg:left-470
  xl:top-110 xl:left-515 xl:w-220
  xxl:top-130 xxl:left-550 xxl:w-260
`;

const RightLetter = tw.div`
  absolute top-155 -right-40 w-100 z-1
  md:top-135 md:-right-475 md:w-125
  lg:right-80
  xl:top-210 xl:right-100 xl:w-180
  xxl:top-260 xxl:-right-60 xxl:w-220
`;

const OpacityMask = styled(motion.div)`
  ${tw`lg:opacity-0`}

  ${down('md')} {
    transform: none !important;
  }
`;

const Images = ({ width }: { width: number }) => {
  const data = useStaticQuery(graphql`
    query {
      imageIllustration: file(relativePath: { eq: "illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 520) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      imageMain: file(relativePath: { eq: "home-page/top.png" }) {
        childImageSharp {
          fluid(maxWidth: 580) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <ImagesWrapper>
        <LeftLetter>
          <LetterLeft />
        </LeftLetter>
        <RightLetter>
          <LetterRight />
        </RightLetter>
        <OpacityMask
          animate={ width >= 1024 && 'visible' }
          transition={{
            opacity: { duration: 1, ease: 'linear' },
            default: { duration: 1.375, ease: [.84, 0, .16, 1] }
          }}
          variants={{ 'visible': { y: -720, opacity: 1 } }}
        >
          <ImageMain fluid={data.imageMain.childImageSharp.fluid} />
        </OpacityMask>
        <OpacityMask
          animate={ width >= 1024 && 'visible' }
          transition={{
            opacity: { duration: .875, ease: 'linear' },
            default: { duration: 1.625, delay: .875, ease: [.88, .14, 0, .99] }
          }}
          variants={{ 'visible': { x: 480, y: -720, opacity: 1 } }}
        >
          <ImageIllustration fluid={data.imageIllustration.childImageSharp.fluid} />
        </OpacityMask>
      </ImagesWrapper>
    </>
  );
};

export default Images;
