import React, { useState } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Img from "gatsby-image";
import tw from "twin.macro";
import { lang, rawName, portableToPlain } from "@utils/helpers";
import { createUrl } from "@utils/caseHelpers";

import ButtonPrimary from "@components/buttons/primary";
import CustomLink from "@components/link";

const Wrapper = tw(motion.div)`
  mb-25
  sm:flex sm:flex-wrap sm:justify-between
  md:mb-70
  lg:mb-150
`;

const Case = styled.div`
  transition: height .3s, margin-bottom .3s;
  ${tw`
    block max-w-340 mb-24 mx-auto
    sm:mx-0 sm:max-w-280
    md:relative md:max-w-340
    lg:max-w-none lg:w-495 lg:mb-75
    xl:w-624
  `}
  
  &:nth-child(2n) {
    ${tw`md:top-40 lg:top-122`}
  }
  
  &:nth-child(n+5) {
    ${tw`h-400 transition-all duration-300 overflow-hidden sm:h-345 md:h-400 lg:h-580 xl:h-695`}
  }
  
  &.lazyload:nth-child(n+5){
    ${tw`h-0`}
  }
`;

const ImageWrapper = tw.div`mb-16 lg:mb-30 overflow-hidden`;

const Name = tw.div`
  mb-10 text-md leading-25 -tracking-013
  lg:text-2xl lg:leading-60 lg:-tracking-024
`;

const ShortDesc = tw.div`
  flex text-second text-sm leading-20 -tracking-021
  lg:text-md lg:leading-30
`;

const Translation = tw.div`font-pangramBold block mr-35 lg:mr-30 lg:text-lg`;

const ButtonWrapper = styled.div<{ isHidden: boolean }>`
  transition: height .4s;
  ${tw`
    flex justify-center h-60 transition-all duration-300 overflow-hidden
    md:h-100
    lg:h-200
  `}
  
  ${({ isHidden }) => isHidden && tw`h-0!`}
`;

const StyledButton = tw.div`mt-10 md:mb-40 lg:mb-140`;

const PortfolioCollection = ({ lazyLoad = false, scroll, width, trigger }: { lazyLoad: boolean, scroll: any, width: number, trigger: Function }) => {
  const [isLastCaseVisible, setLastCaseVisible] = useState(false);
  const { t } = useTranslation();

  const { allSanityCases } = useStaticQuery(graphql`
    query {
      allSanityCases(sort: { fields: order, order: ASC }) {
        nodes {
          order
          title
          slug { current }
          description { _rawEn, _rawPl }
          portfolioImage { asset { fluid { ...GatsbySanityImageFluid }}}
        }
      }
    }
  `);

  const x = useMotionValue(0);

  const Ref = React.useRef();

  const [scrollPercentageStart, setScrollPercentageStart] = React.useState(0);

  const y = useTransform(scroll ? scroll : x, [scrollPercentageStart, scrollPercentageStart + 600], [300, 0]);
  const opacity = useTransform(scroll ? scroll : x, [scrollPercentageStart, scrollPercentageStart + 600], [0, 1]);
  const scale1 = useTransform(scroll ? scroll : x, [scrollPercentageStart + 200, scrollPercentageStart + 800], [1.6, 1]);
  const scaleDelay = useTransform(scroll ? scroll : x, [scrollPercentageStart + 800, scrollPercentageStart + 1400], [1.6, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = (Ref.current.offsetParent.offsetTop - 300) - (window.innerHeight / 2);

    setScrollPercentageStart(offsetStart);
  });

  React.useEffect(() => {
    const timer = setTimeout(() => {
      lazyLoad && trigger(isLastCaseVisible);
    }, 400);
    return () => clearTimeout(timer);
  }, [isLastCaseVisible]);

  return (
    <>
      <Wrapper ref={Ref} style={ width >= 1024 && { y, opacity }}>
        {allSanityCases.nodes.map((node, index) => (
          <Case
            className={`${lazyLoad && !isLastCaseVisible && 'lazyload'}`}
            key={node.title}
          >
            <CustomLink to={createUrl(node.slug.current, lang)} type="noIntl">
              <ImageWrapper>
                <motion.div style={ width >= 1024 && { scale: index <= 1 ? scale1 : scaleDelay }}>
                  <Img fluid={node.portfolioImage.asset.fluid}/>
                </motion.div>
              </ImageWrapper>
              <Name>
                { node.title }
              </Name>
              <ShortDesc>
                <Translation>{t('Home page:Portfolio short')}</Translation>
                { portableToPlain(node.description[rawName]) }
              </ShortDesc>
            </CustomLink>
          </Case>
        ))}
      </Wrapper>
      <ButtonWrapper isHidden={!lazyLoad || isLastCaseVisible}>
        <StyledButton onClick={() => setLastCaseVisible(true)}>
          <ButtonPrimary
            text={'Buttons:Load more'}
            detail={'Dots'}
          />
        </StyledButton>
      </ButtonWrapper>
    </>
  );
};

export default PortfolioCollection;
