import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import tw from "twin.macro";

import { brandsAnimation } from "@utils/keyframes";
import { Eurobank } from "@components/home-page/worked-with/logos-styled";
import * as Logo from "@components/home-page/worked-with/logos-styled";

const brandsAnimationTime = 4.5;

const LogosWrapper = tw.div`
  relative flex flex-wrap items-center max-w-300
  lg:max-w-518 lg:ml-6
`;

const LogoWrapper = styled.div<{ delay: number }>`
  ${up('lg')} {
    animation: ${brandsAnimation} ${brandsAnimationTime}s infinite;
    animation-delay: ${({ delay }) => delay && `calc(${brandsAnimationTime} * .06s * ${delay})` };
  }

  ${tw`
    flex justify-center items-center w-1/3 h-60 border-border
    lg:h-100
  `}
  
  &:nth-child(3n + 1) {
    ${tw`justify-start`}
  }
`;

const StyledSVG = tw.svg`absolute top-0 left-0 w-full h-full`;

const Brands = () => {
  const data = useStaticQuery(graphql`
    query {
      workedWithImage: file(relativePath: { eq: "home-page/copywriter.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      beata: file(relativePath: { eq: "home-page/beata.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoEurobank: file(relativePath: { eq: "home-page/logos/eurobank.png" }) {
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoAmazon: file(relativePath: { eq: "home-page/logos/amazon.png" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoNaturaily: file(relativePath: { eq: "home-page/logos/naturaily.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoPlus: file(relativePath: { eq: "home-page/logos/plus.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoBlueMedia: file(relativePath: { eq: "home-page/logos/blue-media.png" }) {
        childImageSharp {
          fluid(maxWidth: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoCrusar: file(relativePath: { eq: "home-page/logos/crusar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoFiniata: file(relativePath: { eq: "home-page/logos/finiata.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoDroidsOnRoids: file(relativePath: { eq: "home-page/logos/droids-on-roids.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoAxellLogistics: file(relativePath: { eq: "home-page/logos/axell-logistics.png" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <LogosWrapper>
      <LogoWrapper delay={ 0 }>
        <Eurobank fluid={data.logoEurobank.childImageSharp.fluid}/>
      </LogoWrapper>
      <LogoWrapper delay={ 1 }>
        <Logo.Amazon fluid={data.logoAmazon.childImageSharp.fluid}/>
      </LogoWrapper>
      <LogoWrapper delay={ 2 }>
        <Logo.Naturaily fluid={data.logoNaturaily.childImageSharp.fluid}/>
      </LogoWrapper>
      <LogoWrapper delay={ 1 }>
        <Logo.Plus fluid={data.logoPlus.childImageSharp.fluid}/>
      </LogoWrapper>
      <LogoWrapper delay={ 2 }>
        <Logo.BlueMedia fluid={data.logoBlueMedia.childImageSharp.fluid}/>
      </LogoWrapper>
      <LogoWrapper delay={ 3 }>
        <Logo.Crusar fluid={data.logoCrusar.childImageSharp.fluid}/>
      </LogoWrapper>
      <LogoWrapper delay={ 2 }>
        <Logo.Finiata fluid={data.logoFiniata.childImageSharp.fluid}/>
      </LogoWrapper>
      <LogoWrapper delay={ 3 }>
        <Logo.DroidsOnRoids fluid={data.logoDroidsOnRoids.childImageSharp.fluid}/>
      </LogoWrapper>
      <LogoWrapper delay={ 4 }>
        <Logo.AxellLogistics fluid={data.logoAxellLogistics.childImageSharp.fluid}/>
      </LogoWrapper>
      <StyledSVG viewBox="0 0 518 300" stroke="#eeeeee">
        <path d="M 0 100 l 518 0" />
        <path d="M 172 0 l 0 300" />
        <path d="M 0 201 l 518 0" />
        <path d="M 345 0 l 0 300" />
      </StyledSVG>
    </LogosWrapper>
  );
};

export default Brands;
