import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

type SliderProps = {
  testimonials: string[],
  visibleTestimonial: number,
  handleNavigation: Function,
};

const Wrapper = tw.div`flex justify-center lg:pt-228`;

const DotWrapper = tw.div`flex items-center h-16 p-8 cursor-pointer`;

const Dot = styled.span<{ active: boolean }>`
  ${tw`inline-block rounded-full transition-all duration-300`}

  ${({ active }) => active ? tw`h-8 w-8 bg-orange` : tw`h-4 w-4 bg-border`}
`;

const TestimonialsSlider = ({ testimonials, visibleTestimonial, handleNavigation }: SliderProps) => (
  <Wrapper>
    {testimonials.map((name, index) => (
      <DotWrapper onClick={() => handleNavigation(index)} key={index}>
        <Dot active={index === visibleTestimonial} />
      </DotWrapper>
    ))}
  </Wrapper>
);

export default TestimonialsSlider;
