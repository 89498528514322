import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import Img from "gatsby-image";
import tw from "twin.macro";

import Collection from "@components/portfolio/collection";
import ArrowPrimary from "@components/arrows/primary"
import ArrowRed from "@components/arrows/red"

const Wrapper = tw.div`relative pt-104 px-20 lg:pt-294 lg:px-0`;

const Title = tw(motion.div)`
  absolute top-14 right-0 w-250 text-md leading-25 -tracking-013
  lg:top-173 lg:right-140 lg:w-380 lg:text-4xl lg:leading-60
`;

const Border = tw.div`
  hidden
  lg:block lg:absolute lg:top-0 lg:-left-320 lg:w-1920 lg:border-t lg:border-border
`;

const Arrow = tw(motion.div)`
  absolute! top-10 -right-48 w-150 -z-1
  lg:top-164 lg:-right-200 lg:w-260
`;

const ArrowBlue = tw.div`
  absolute! hidden -z-1
  lg:block lg:top-1196 lg:right-40 lg:w-223
`;

const Illustration = tw(motion.div)`
  absolute! hidden
  md:block md:top-44 md:-left-310 md:w-558
`;

const Portfolio = ({ scroll, width, trigger }: { scroll: any, width: number, trigger: Function }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "illustration-orange.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const RefWhoWeAre = React.useRef();

  const [scrollPercentageStart, setScrollPercentageStart] = React.useState(0);

  const y = useTransform(scroll, [scrollPercentageStart, scrollPercentageStart + 300], [300, 0]);
  const y2 = useTransform(scroll, [scrollPercentageStart, scrollPercentageStart + 300, scrollPercentageStart + 1000], [600, 0, -400]);
  const opacity = useTransform(scroll, [scrollPercentageStart, scrollPercentageStart + 350], [0, 1]);

  React.useLayoutEffect(() => {
    if (!RefWhoWeAre.current) return;

    const offsetStart = RefWhoWeAre.current.offsetTop - window.innerHeight + 150;

    setScrollPercentageStart(offsetStart);
  });

  return (
    <Wrapper ref={RefWhoWeAre}>
      <Border />
      <Title style={ width >= 1024 && { y, opacity }}>
        {t('Home page:Portfolio')}
      </Title>
      <Arrow style={ width >= 1024 && { y }}>
        <ArrowRed />
      </Arrow>
      <ArrowBlue>
        <ArrowPrimary />
      </ArrowBlue>
      <Illustration style={ width >= 1024 && { y: y2 }}>
        <Img fluid={data.illustration.childImageSharp.fluid} />
      </Illustration>
      <Collection trigger={ trigger } lazyLoad width={ width } scroll={ scroll } />
    </Wrapper>
  );
};

export default Portfolio;
