import React from "react";
import styled from 'styled-components';
import tw from 'twin.macro';

import { scrollMouseAnimation } from "@utils/keyframes";

const Mouse = tw.div`
  w-3 h-20 py-6 px-8 bg-white border-2 border-primary opacity-75 box-content rounded-3xl
`;

const Scroller = styled.div`
  animation: ${scrollMouseAnimation} 2s infinite;
  ${tw`h-4 bg-primary rounded-full`}
`;

const MouseScrollIcon = () => (
  <Mouse>
    <Scroller />
  </Mouse>
);

export default MouseScrollIcon;
