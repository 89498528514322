import React from "react";
import styled from "styled-components";

import { dot, line } from "@utils/keyframes";

const animationDuration = 7;

const StyledDot = styled.path<{ animationDelay: number, transformOrigin: string }>`
  transform: scale(0);
  transform-origin: ${({ transformOrigin }) => transformOrigin };
  animation: ${dot} ${animationDuration}s infinite;
  animation-delay: ${({ animationDelay }) => `${animationDelay}s` };
`;

const StyledLine = styled.path<{ animationDelay: number }>`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${line} ${animationDuration}s infinite;
  animation-delay: ${({ animationDelay }) => `${animationDelay}s` };
`;

const Details = () => (
  <svg viewBox="0 0 414 329">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g stroke="#761113" strokeWidth="0.25" strokeLinejoin="round">
        <StyledLine animationDelay={ 2.4 } d="M 61 67 l 59 90" />
        <StyledLine animationDelay={ 2 } d="M 389 303 l -108 26" />
        <StyledLine animationDelay={ 3.1 } d="M 414 211 l -97 71" />
      </g>
      <StyledDot animationDelay={ 3 } transformOrigin="20px 10px" fill="#EE3F2E" d="M32,16 C32,24.848 24.848,32 16,32 C7.168,32 0,24.848 0,16 C0,7.152 7.168,0 16,0 C24.848,0 32,7.152 32,16" />
      <StyledDot animationDelay={ 3.6 } transformOrigin="100px 220px" fill="#00A1D6" d="M102,219.5 C102,224.190207 97.9830508,228 93,228 C88.0338983,228 84,224.190207 84,219.5 C84,214.809793 88.0338983,211 93,211 C97.9830508,211 102,214.809793 102,219.5" />
      <StyledDot animationDelay={ 2.8 } transformOrigin="270px 200px" fill="#00A1D6" d="M284.336,201.5 C284.336,205.801 281.056,209.286 277.008,209.286 C272.944,209.286 269.664,205.801 269.664,201.5 C269.664,197.199 272.944,193.714 277.008,193.714 C281.056,193.714 284.336,197.199 284.336,201.5" />
    </g>
  </svg>
);

export default Details;
