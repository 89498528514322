import React, { useState } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useInView } from 'react-intersection-observer';
import { StickyContainer, Sticky } from 'react-sticky';
import { motion, useTransform } from "framer-motion";
import scrollTo from "gatsby-plugin-smoothscroll";
import { graphql, useStaticQuery } from "gatsby";
import { useSwipeable } from "react-swipeable";
import { useTranslation } from "react-i18next";
import { bgSize } from "@utils/keyframes";
import { lang, rawName } from "@utils/helpers";
import styled from "styled-components";
import Img from "gatsby-image";
import tw from "twin.macro";

import Navigation from "@components/home-page/testimonials/navigation";
import TestimonialsSlider from "@components/home-page/testimonials/slider";
import Details from "@components/svg/details";
import PortableText from "@components/portableText";

type TestimonialsProps = {
  scroll: any,
  width: number,
  triggerUseLayoutEffect: Function,
  trigger: boolean
};

const StickyWrapper = styled.div`
  ${tw`
    relative flex flex-wrap mt-20 pt-38 px-20 pb-55
    md:pb-80
    lg:mt-0 lg:pt-0 lg:px-0 lg:pb-168
    xl:pb-80
  `}
  
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active {
    transition: opacity .4s;
  }
`;

const TestimonialsHeaderWrapper = tw.div`
  w-3/4 mb-20
  md:mb-40
  lg:w-1/2 lg:mb-0
`;

const OpinionWrapper = tw(motion.div)`
  w-full
  md:w-2/3 md:mx-auto
  lg:w-5/12 lg:mx-0
`;

const SliderWrapper = tw(motion.div)`
  w-full
  lg:absolute lg:top-228 lg:left-50 lg:w-auto
  lg:top-0 lg:bottom-90 lg:w-224
`;

const Visual = tw(motion.div)`
  absolute! top-21 -right-142 w-228 transform rotate-170
  lg:top-155 lg:-left-174 lg:right-auto lg:w-416 lg:rotate-0
`;

const Header = tw.div`
  ml-10 mb-12 text-md -tracking-013
  md:text-xl
  lg:ml-50 lg:mb-20 lg:text-4xl lg:leading-60 lg:-tracking-028
`;

const Description = tw.div`
  flex w-200 mb-12 text-sm text-second leading-20 -tracking-021
  lg:w-auto lg:text-md lg:leading-30 lg:-tracking-027
`;

const DescriptionSpan = tw.span`
  font-pangramBold block mr-22
  lg:mr-40 lg:text-lg
`;

const Wrapper = tw.div`mb-20 md:mb-30 lg:mb-0`;

const Mark = tw.div`text-2xl text-orange leading-40 -tracking-024 lg:leading-60`;

const Opinion = styled.div`
  ${tw`
    mb-22 mr-10 text-xl leading-40 -tracking-016
    lg:text-2xl lg:leading-60 lg:mb-55 lg:-tracking-024
    xl:whitespace-pre-line
  `}
  
  span {
    ${tw`font-normal bg-no-repeat`}
    background-image: linear-gradient(120deg, #FEE4E2 0%, #FEE4E2 100%);
    background-position: 0 100%;
    background-size: 100% 0;
  }
  
  &.active span {
    animation: ${bgSize} .4s .4s forwards;
  }
`;

const PersonWrapper = tw.div`flex mb-10 lg:mb-15`;

const Avatar = tw.div`
  w-40 h-40 mr-10 rounded-full overflow-hidden
  lg:w-80 lg:h-80 lg:mr-30
`;

const Person = tw.div`
  text-sm text-second leading-30 -tracking-021
  lg:text-md lg:-tracking-027
`;

const Name = tw.div`font-pangramBold lg:mb-8`;

const ShortDesc = tw.div`
  flex text-sm text-second leading-22 -tracking-021
  lg:max-w-340 lg:ml-110 lg:-mt-20 lg:text-md lg:leading-30 lg:-tracking-027
`;

const ShortDescSpan = tw.span`
  font-pangramBold mr-10
  lg:mr-35 lg:text-lg
`;

const Testimonials = ({ width, scroll, triggerUseLayoutEffect, trigger }: TestimonialsProps) => {
  const { t } = useTranslation();

  const { allSanityTestimonials } = useStaticQuery(graphql`
    query {
      allSanityTestimonials (filter: { shown: { eq: true }}, sort: { fields: [order], order: ASC }) {
        nodes {
          shown
          order
          name
          image { asset { fluid { ...GatsbySanityImageFluid } } }
          project { en, pl }
          position { en, pl }
          testimonial { _rawEn, _rawPl}
        }
      }
    }
  `);
  const testimonials: string[] = allSanityTestimonials.nodes.map((node) => node.name );

  const { ref, inView } = useInView();

  const [visibleTestimonial, setTestimonial] = useState(0);
  const data = allSanityTestimonials.nodes[visibleTestimonial];

  const handleNavigation = (val: number) => {
    setTestimonial(val);
  };

  const RefTestimonials = React.useRef();

  const [scrollPercentageStart, setScrollPercentageStart] = React.useState(0);
  const [sectionEnd, setSectionEnd] = React.useState(1);

  const y = useTransform(scroll, [scrollPercentageStart, scrollPercentageStart + 600], [400, 0]);
  const opacity2 = useTransform(scroll, [scrollPercentageStart, scrollPercentageStart + 600], [0, 1]);
  const opacity = useTransform(scroll, [scrollPercentageStart + 100, scrollPercentageStart + 450], [0, 1]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      inView && scrollTo('#testimonials');
    }, 100);
    return () => clearTimeout(timer);
  }, [visibleTestimonial])

  React.useLayoutEffect(() => {
    if (!RefTestimonials.current) return;

    const offsetStart = RefTestimonials.current.offsetTop - window.innerHeight;
    const offsetEnd = offsetStart + RefTestimonials.current.clientHeight;

    setScrollPercentageStart(offsetStart);
    setSectionEnd(offsetEnd);
  }, [trigger]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      const isLastOpinion = visibleTestimonial === testimonials.length - 1;

      return !isLastOpinion && handleNavigation(visibleTestimonial + 1)
    },
    onSwipedRight: () => {
      const isFirstOpinion = visibleTestimonial === 0;

      return !isFirstOpinion && handleNavigation(visibleTestimonial - 1)
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: false
  });

  return (
    <StickyWrapper ref={RefTestimonials} id="testimonials">
      <TestimonialsHeaderWrapper>
        <StickyContainer className="h-full">
          <Sticky>{({ style }: { style: Record<string, string> }) => (
            <div style={ style }>
              <div className="lg:pt-70">
                <motion.div style={ width >= 1024 && { opacity }}>
                  <Header>
                    {t('Testimonials:Header')}
                  </Header>
                  <Description>
                    <DescriptionSpan>
                      {t('Testimonials:Short')}
                    </DescriptionSpan>
                    {t('Testimonials:Description')}
                  </Description>
                  <Navigation
                    visibleTestimonial={visibleTestimonial}
                    testimonialsLenght={testimonials.length}
                    handleNavigation={handleNavigation}
                  />
                </motion.div>
              </div>
            </div>
          )}</Sticky>
        </StickyContainer>
      </TestimonialsHeaderWrapper>
      <Visual style={ width >= 1024 && { opacity }}>
        <Details />
      </Visual>
      <OpinionWrapper style={ width >= 1024 && { y, opacity: opacity2 }} {...handlers}>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={visibleTestimonial}
            addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
            onEntering={() => triggerUseLayoutEffect(visibleTestimonial)}
            classNames="fade"
          >
            <div>
              <Wrapper>
                <Mark>
                  {t(`Testimonials:Mark`)}
                </Mark>
                  <Opinion ref={ref} className={ inView ? 'active' : '' }>
                  <PortableText blocks={data.testimonial[rawName]} />
                  </Opinion>
                <PersonWrapper>
                  <Avatar>
                    <Img fluid={data.image.asset.fluid} />
                  </Avatar>
                  <Person>
                    <Name>
                      { data.name }
                    </Name>
                    <div>
                      { data.position[lang] }
                    </div>
                  </Person>
                </PersonWrapper>
                <ShortDesc>
                  <ShortDescSpan>
                    {t(`Testimonials:Description short`)}
                  </ShortDescSpan>
                  { data.project[lang] }
                </ShortDesc>
              </Wrapper>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </OpinionWrapper>
      <SliderWrapper style={ width >= 1024 && { opacity }}>
        <StickyContainer className="h-full">
          <Sticky>{({ style }: { style: Record<string, string> }) => (
            <div style={ style }>
              <TestimonialsSlider
                testimonials={testimonials}
                visibleTestimonial={visibleTestimonial}
                handleNavigation={handleNavigation}
              />
            </div>
          )}</Sticky>
        </StickyContainer>
      </SliderWrapper>
    </StickyWrapper>
  );
};

export default Testimonials;
