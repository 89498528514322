import React from "react";
import styled from "styled-components";
import Img from "@images/scroll.svg";
import tw from "twin.macro";

type NavigationProps = {
  visibleTestimonial: number,
  testimonialsLenght: number,
  handleNavigation: Function
};

const Wrapper = tw.div`hidden lg:flex lg:justify-end lg:max-w-440 lg:mt-35`;

const NavButton = styled.button`
  ${tw`
    flex justify-center items-center w-44 h-44 ml-10 bg-orange border-orange border-2 rounded-full
    transition-colors duration-400 focus:outline-none
  `}
  
  .svg-color {
    stroke: #FFF;
    transition: all .4s;

    polyline {
      transform: translateY(-3px);
      transition: transform .4s;
    }
  
    line {
      transform: scaleY(0);
      transition: transform .4s;
    }
  }
  
  &.disabled {
    ${tw`opacity-50 cursor-default`}
    
    &:hover {
      ${tw`bg-orange`}
      
      .svg-color {
        stroke: #FFF;

        polyline {
          transform: translateY(-3px);
        }
      
        line {
          transform: scaleY(0);
        }
      }
    }
  }
  
  &:hover {
    ${tw`bg-white`}
    
    .svg-color {
      stroke: #F06140;

      polyline {
        transform: translateY(0);
      }
    
      line {
        transform: scaleY(1);
      }
    }
  }
`;

const Arrow = styled(Img)`
  ${tw`transition-opacity duration-300 transform`}
`;

const ArrowNext = tw(Arrow)`rotate-90`;

const ArrowPrev = tw(Arrow)`-rotate-90`;

const TestimonialsNavigation = ({
  visibleTestimonial,
  testimonialsLenght,
  handleNavigation
}: NavigationProps) => {
  const isFirstOpinion = visibleTestimonial === 0;
  const isLastOpinion = visibleTestimonial === testimonialsLenght - 1;

  return (
    <Wrapper>
      <NavButton
        className={ isFirstOpinion && 'disabled' }
        onClick={() =>  !isFirstOpinion && handleNavigation(visibleTestimonial - 1)}
      >
        <ArrowNext />
      </NavButton>
      <NavButton
        className={ isLastOpinion && 'disabled' }
        onClick={() => !isLastOpinion && handleNavigation(visibleTestimonial + 1)}
      >
        <ArrowPrev />
      </NavButton>
    </Wrapper>
  );
};

export default TestimonialsNavigation;
