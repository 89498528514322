import React from "react";
import styled from "styled-components";

import { dot, line } from '@utils/keyframes';
import { headerAnimationDuration } from "@utils/variables";

const StyledBlueDot = styled.path`
  transform: scale(0);
  animation: ${dot} ${headerAnimationDuration}s 1.4s infinite;
`;

const StyledLine = styled.path`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  transform: rotate(180deg);
  animation: ${line} ${headerAnimationDuration}s 2s infinite;
`;

const DetailsSmall = () => (
  <svg viewBox="0 0 215 119" preserveAspectRatio="xMidYMid slice">
    <g strokeLinejoin="round" stroke="#761113" strokeWidth="0.25" style={{ transform: 'translate(54px, 105px)' }}>
      <StyledLine d=" M-54.0890007019043,12.831000328063965 C-54.0890007019043,12.831000328063965 54.090999603271484,-12.829000473022461 54.090999603271484,-12.829000473022461" />
    </g>
    <g style={{ transform: 'translate(202px, 10px)' }}>
      <StyledBlueDot fill="#00a1d6" d=" M8.918000221252441,0.003000000026077032 C8.918000221252441,4.61299991607666 4.938000202178955,8.36299991607666 -0.0020000000949949026,8.36299991607666 C-4.921999931335449,8.36299991607666 -8.92199993133545,4.61299991607666 -8.92199993133545,0.003000000026077032 C-8.92199993133545,-4.617000102996826 -4.921999931335449,-8.366999626159668 -0.0020000000949949026,-8.366999626159668 C4.938000202178955,-8.366999626159668 8.918000221252441,-4.617000102996826 8.918000221252441,0.003000000026077032" />
    </g>
  </svg>
);

export default DetailsSmall;
