import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Img from "gatsby-image";
import tw from "twin.macro";

import Brands from "@components/home-page/worked-with/brands";
import ArrowPrimary from "@components/arrows/primary"
import DetailsSmall from "@components/svg/details-small"

const Wrapper = tw(motion.div)`
  mb-65
  sm:flex sm:justify-between
  lg:mb-0
`;

const ImageWrapper = styled(motion.div)`
  left: calc(50% - 4px);
  ${tw`
    relative w-322 mb-10 transform -translate-x-1/2
    sm:left-0 sm:translate-x-0
    lg:left-0 lg:w-1/2
  `}
`;

const ImageBackground = tw.div`
  w-280 h-280 ml-30 mb-60 bg-yellow overflow-hidden
  lg:w-430 lg:h-455 lg:mt-15 lg:ml-0
  xl:w-520 xl:h-520
`;

const MotionWrapper = tw(motion.div)`relative`;

const StyledImg = tw(Img)`
  absolute! right-0 w-256 ml-40
  lg:w-420
  xl:w-480 xl:ml-40
`;

const DetailsWrapper = styled.div`
  transform: rotate(200deg) scale(1,-1);
  ${tw`
    absolute -bottom-20 -left-20 w-120
    lg:bottom-10 lg:-left-50 lg:w-160
    xl:bottom-20 xl:-left-80 xl:w-200
  `}
  
  svg g:first-child {
    transform: translate(100px, 105px) rotate(45deg) !important;
  }
`;

const ArrowWrapper = styled.div`
  ${tw`
    absolute -bottom-50 -right-10 transform rotate-90
    lg:bottom-0 lg:right-40
    xl:bottom-220 xl:right-180 xl:rotate-0
  `}

  svg {
    ${tw`w-120 lg:w-160 xl:w-210`}
  }
`;

const BrandsWrapper = tw.div`ml-20`;

const Header = tw.div`
  mb-10 text-md leading-25 -tracking-013
  lg:mb-65 lg:text-4xl lg:leading-60 lg:-tracking-028
`;

const Description = tw.div`
  mb-35 text-sm text-second leading-20 -tracking-021
  lg:text-md lg:-tracking-027
`;

const DescriptionDetail = tw.span`
  font-pangramBold mr-22
  lg:mr-40 lg:text-lg lg:-tracking-03
`;

const WorkedWith = ({ scroll, width }: { scroll: any, width: number }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      workedWithImage: file(relativePath: { eq: "home-page/copywriter.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      beata: file(relativePath: { eq: "home-page/beata.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoEurobank: file(relativePath: { eq: "home-page/logos/eurobank.png" }) {
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoAmazon: file(relativePath: { eq: "home-page/logos/amazon.png" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoNaturaily: file(relativePath: { eq: "home-page/logos/naturaily.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoPlus: file(relativePath: { eq: "home-page/logos/plus.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoBlueMedia: file(relativePath: { eq: "home-page/logos/blue-media.png" }) {
        childImageSharp {
          fluid(maxWidth: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoCrusar: file(relativePath: { eq: "home-page/logos/crusar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoFiniata: file(relativePath: { eq: "home-page/logos/finiata.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoDroidsOnRoids: file(relativePath: { eq: "home-page/logos/droids-on-roids.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      logoAxellLogistics: file(relativePath: { eq: "home-page/logos/axell-logistics.png" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const BrandsRef = React.useRef();

  const [offsetStart, setSectionStart] = React.useState(0);
  const [sectionMiddle, setSectionMiddle] = React.useState(1);
  const [sectionEnd, setSectionEnd] = React.useState(1);

  const y = useTransform(scroll, [offsetStart, sectionMiddle + 100], [300, -100]);
  const opacity = useTransform(scroll, [offsetStart, sectionMiddle], [0, 1]);
  const rotate = useTransform(scroll, [offsetStart, sectionMiddle + 100], [-8, 0]);

  const opacityBeata = useTransform(scroll, [sectionMiddle, sectionEnd - 200], [0, 1]);
  const rightBeata = useTransform(scroll, [sectionMiddle, sectionEnd - 200], [600, 0]);

  React.useLayoutEffect(() => {
    if (!BrandsRef.current) return;

    const offsetStart = BrandsRef.current.offsetTop - window.innerHeight;
    const middle = offsetStart + (window.innerHeight / 2);
    const offsetEnd = middle + (window.innerHeight / 2);

    setSectionStart(offsetStart);
    setSectionMiddle(middle);
    setSectionEnd(offsetEnd);
  });

  return (
    <Wrapper ref={BrandsRef} style={ width >= 1024 && { y, opacity }}>
      <ImageWrapper style={ width >= 1024 && { rotate }}>
        <ImageBackground>
          <MotionWrapper style={ width >= 1024 && { opacity: opacityBeata, x: rightBeata }}>
            <StyledImg fluid={data.beata.childImageSharp.fluid} />
          </MotionWrapper>
        </ImageBackground>
        <DetailsWrapper>
          <DetailsSmall />
        </DetailsWrapper>
        <ArrowWrapper>
          <ArrowPrimary />
        </ArrowWrapper>
      </ImageWrapper>
      <BrandsWrapper>
        <Header>
          {t('Home page:What we done1')}
          <br/>
          {t('Home page:What we done2')}
        </Header>
        <Description>
          <DescriptionDetail>
            {t('Home page:Short')}
          </DescriptionDetail>
          {t('Home page:Check for who we work')}
        </Description>
        <Brands />
      </BrandsWrapper>
    </Wrapper>
  );
};

export default WorkedWith;
