import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useViewportScroll } from "framer-motion";
import useWindowSize from "@utils/useWindowSize";

import Layout from '@layouts/index';
import SEO from '@components/seo';
import HomePageHero from "@components/home-page/hero";
import Gradient from "@components/home-page/gradient";
import WhoWeAreHead from "@components/home-page/who-we-are/head";
import WhoWeAreMore from "@components/home-page/who-we-are/more";
import WorkedWith from "@components/home-page/worked-with";
import Portfolio from "@components/portfolio";
import Testimonials from "@components/home-page/testimonials";
import CTA from "@components/cta";


const IndexPage = () => {
  const intl = useIntl();
  const { scrollY } = useViewportScroll();
  const { width } = useWindowSize();

  const [triggerUseLayoutEffect, runTrigger] = useState(0);
  const [trigger, setTrigger] = useState(false);

  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'SEO.Title.Home page' })}
        description={intl.formatMessage({ id: 'SEO.Description.Home page' })}
      />
      <HomePageHero width={ width } scroll={ scrollY } />
      <Gradient />
      <WhoWeAreHead width={ width } scroll={ scrollY } />
      <WhoWeAreMore width={ width } scroll={ scrollY } />
      <WorkedWith width={ width } scroll={ scrollY } />
      <Portfolio trigger={ setTrigger } width={ width } scroll={ scrollY } />
      <Testimonials trigger={ trigger } triggerUseLayoutEffect={runTrigger} width={ width } scroll={ scrollY } />
      <CTA trigger={ trigger } triggerUseLayoutEffect={ triggerUseLayoutEffect } width={ width } scroll={ scrollY } />
    </Layout>
  );
};

export default IndexPage;
