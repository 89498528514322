import React from "react";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

import LinkPrimary from "@components/links/primary";
import ArrowPrimary from "@components/arrows/primary";
import ArrowSecondary from "@components/arrows/secondary";
import Emoji from "@components/home-page/who-we-are/emoji"

const Wrapper = tw.div`
  relative text-center mb-50 pt-50 px-12 pb-108
  sm:mb-70 sm:pt-80
  xl:pt-200 xl:pb-100
`;

const Paragraph = tw(motion.p)`
  mb-20 text-sm text-second leading-22 -tracking-021
  sm:text-base
  xl:mb-0 xl:font-pangramBold xl:mr-44
`;

const EmojiWrapper = tw(motion.div)`xl:opacity-0`;

const CallToActionWrapper = tw(motion.div)`xl:flex xl:justify-center xl:items-center`;

const WhoWeAreHead = ({ scroll, width }: { scroll: any, width: Number }) => {
  const { t } = useTranslation();
  const RefWhoWeAre = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);
  const [sectionEnd, setSectionEnd] = React.useState(1);

  const y = useTransform(scroll, [sectionStart + 50 , sectionEnd], [200, 0]);
  const ySlow = useTransform(scroll, [sectionStart + 50 , sectionEnd], [140, 0]);
  const rotate = useTransform(scroll, [sectionStart + 50 , sectionEnd], [-9, 0]);
  const opacity = useTransform(scroll, [sectionStart + 50 , sectionEnd], [0, 1]);
  const opacitySlow = useTransform(scroll, [sectionStart + 50 , sectionEnd], [0, 1]);

  React.useLayoutEffect(() => {
    if (!RefWhoWeAre.current) return;

    const offsetStart = RefWhoWeAre.current.offsetTop - window.innerHeight;
    const offsetEnd = offsetStart + RefWhoWeAre.current.clientHeight;

    setSectionStart(offsetStart);
    setSectionEnd(offsetEnd);
  });

  return (
    <Wrapper ref={RefWhoWeAre}>
      <ArrowPrimary />
      <EmojiWrapper style={ width >= 1024 && { y, opacity, rotate } }>
        <Emoji />
      </EmojiWrapper>
      <CallToActionWrapper style={ width >= 1024 && { y: ySlow, opacity: opacitySlow } }>
        <Paragraph>
          {t('Home page:Work for you')}
        </Paragraph>
        <LinkPrimary className={'mx-auto xl:mx-0'} />
      </CallToActionWrapper>
      <ArrowSecondary />
    </Wrapper>
  );
};

export default WhoWeAreHead;
