import React from "react";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import { down } from "styled-breakpoints";
import styled from "styled-components";
import tw from "twin.macro";

import BackgroundDetails from "@components/home-page/hero/background-details";
import Images from "@components/home-page/hero/images";
import MouseScrollIcon from "@components/mouse-scroll-icon";

const Hero = tw.div`relative flex flex-col sm:flex-row md:flex-col-reverse xxl:mt-80`;

const TextsWrapper = tw.div`
  flex flex-col max-w-250 ml-40 mb-12
  sm:ml-auto
  md:mb-0 md:ml-0 md:flex-row-reverse md:max-w-none md:justify-between
  xxl:mt-100
`;

const Header = styled(motion.h1)`
  ${tw`
    mb-10 text-xl leading-33 -tracking-016
    md:max-w-250 md:-tracking-028
    lg:relative lg:top-120 lg:text-2xl lg:leading-60 lg:max-w-399 lg:mr-120 lg:mb-0 lg:opacity-0
    xl:text-4xl xl:max-w-475 xl:mr-45
  `}
  
  ${down('md')} {
    transform: none !important;
  }
`;

const DescriptionWrapper = styled(motion.div)`
  ${tw`flex flex-col lg:relative lg:top-240 lg:opacity-0`}
  
  ${down('md')} {
    transform: none !important;
  }
`;

const Description = styled.p`
  ${tw`
    flex text-sm text-second leading-20 -tracking-021
    md:max-w-250 md:-tracking-027
    lg:text-md lg:leading-30 lg:max-w-370
  `}
  
  br {
    ${tw`hidden xl:block`}
  }
`;

const MouseWrapper = tw(motion.div)`
  hidden
  lg:block lg:fixed lg:left-1/2 lg:bottom-30 lg:z-2
`;

const DescriptionCharacter = tw.span`font-pangramBold mr-15 leading-32 md:-tracking-03 lg:text-lg`;

const HomePageHero = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const opacity = useTransform(scroll, [50, 100], [1, 0]);

  return (
    <Hero>
      <TextsWrapper>
        <Header
          initial={ width >= 1024 && 'hidden'}
          animate={ width >= 1024 && 'visible' }
          transition={{
            y: { duration: .917, delay: .5, ease: [.88, .14, .12, .86] },
            opacity: { duration: .458, delay: .75, ease: 'linear' },
            rotate: { duration: .917, delay: .5, ease: [.88, .14, .12, .86] }
          }}
          variants={{
            'visible': { y: -120, opacity: 1, rotate: 0 },
            'hidden': { rotate: -8 }
          }}
        >
          {t('Home page:Header')}
        </Header>
        <DescriptionWrapper
          animate={ width >= 1024 && 'visible'}
          transition={{
            opacity: { duration: .542, ease: 'linear' },
            default: { duration: .875, delay: .542, ease: [.84, 0, .16, 1] }
          }}
          variants={{ 'visible': { y: -280, opacity: 1 } }}
        >
          <Description>
            <DescriptionCharacter>
              {t('Description Character')}
            </DescriptionCharacter>
            {t('Home page:Description1')}
            <br/>
            {t('Home page:Description2')}
          </Description>
        </DescriptionWrapper>
      </TextsWrapper>
      <BackgroundDetails />
      <Images width={width} />
      <MouseWrapper style={{ opacity }}>
        <MouseScrollIcon />
      </MouseWrapper>
    </Hero>
  );
};

export default HomePageHero;
