import React from "react";
import styled, { keyframes } from 'styled-components';
import { useTranslation } from "react-i18next";
import { up } from "styled-breakpoints";
import tw from "twin.macro";

const emojiAnimationReverse = keyframes`
  0% { width: 50px; transform: scale(1) rotate(0); opacity: 1; }
  29% { width: 50px; transform: scale(1) rotate(0); opacity: 1; }
  31% { transform: scale(0) rotate(-10deg); opacity: 0; }
  32% { width: 0; }
  50% { width: 0; }
  52% { transform: scale(0) rotate(-10deg); opacity: 0; }
  54% { width: 50px; transform: scale(1) rotate(0); opacity: 1; }
  100% { width: 50px; transform: scale(1) rotate(0); opacity: 1; }
`;

const Heading = tw.h2`
  max-w-290 mx-auto mb-10 text-xl leading-33 -tracking-016
  sm:max-w-580 sm:text-2xl sm:leading-50 sm:mb-30
  xl:max-w-none xl:mx-105 xl:mb-68 xl:text-8xl xl:leading-80
`;

const Emoticon = tw.div`inline-block`;

const StyledEmoji = styled(Emoticon)<{ delay: number }>`
  ${up('xl')} {
    width: 50px;
    animation: ${emojiAnimationReverse} 14s infinite;
    animation-delay: ${({ delay }) => delay && `${delay}s`};
  }
`;

const Emoji = () => {
  const { t } = useTranslation();

  return (
    <Heading>
      {t('Home page:Who we are')}
      <br className="hidden xl:block" />
      {t('Home page:Who we are1')}
      <StyledEmoji delay={ 0 }>
        {t('Home page:Who we are2')}
      </StyledEmoji>
      {t('Home page:Who we are3')}
      <StyledEmoji delay={ 7 }>
        {t('Home page:Who we are4')}
      </StyledEmoji>
      {t('Home page:Who we are5')}
      <StyledEmoji delay={ 2.2 }>
        {t('Home page:Who we are6')}
      </StyledEmoji>
      {t('Home page:Who we are7')}
      <StyledEmoji delay={ 5 }>
        {t('Home page:Who we are8')}
      </StyledEmoji>
      {t('Home page:Who we are9')}
      <StyledEmoji delay={ 9 }>
        {t('Home page:Who we are10')}
      </StyledEmoji>
      {t('Home page:Who we are11')}
    </Heading>
  );
};

export default Emoji;
