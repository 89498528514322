import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

export const Eurobank = styled(Img)`
  ${tw`w-68 lg:w-110`}
  
  & > div {
    padding-bottom: 17.5% !important;
  }
`;

export const Amazon = styled(Img)`
  ${tw`w-62 lg:w-102`}
  
  & > div {
    padding-bottom: 30% !important;
  }
`;

export const Naturaily = styled(Img)`
  ${tw`w-60 lg:w-98`}
  
  & > div {
    padding-bottom: 34% !important;
  }
`;

export const Plus = styled(Img)`
  ${tw`w-52 lg:w-88`}
  
  & > div {
    padding-bottom: 34% !important;
  }
`;

export const BlueMedia = styled(Img)`
  ${tw`w-40 lg:w-65`}
  
  & > div {
    padding-bottom: 61% !important;
  }
`;

export const Crusar = tw(Img)`w-65 lg:w-108`;

export const Finiata = styled(Img)`
  ${tw`w-60 lg:w-98`}
  
  & > div {
    padding-bottom: 24.5% !important;
  }
`;

export const DroidsOnRoids = tw(Img)`w-44 lg:w-78`;

export const AxellLogistics = styled(Img)`
  ${tw`w-80 lg:w-135`}
  
  & > div {
    padding-bottom: 18.7% !important;
  }
`;