import React from 'react';
import styled, { keyframes } from "styled-components";

import { headerAnimationDuration } from "@utils/variables";
import { letterPathLength } from "@utils/keyframes";

const letterLeft = keyframes`
  0% { opacity: 0 }
  3% { opacity: 0 }
  12.2% { opacity: 1; }
  66.1% { opacity: 1; }
  72.2% { opacity: 0 }
  100% { opacity: 0 }
`;

const arrow = keyframes`
  0% { transform: scale(0) rotate(40deg); }
  3% { transform: scale(0) rotate(40deg); }
  12.2% { transform: none; }
  66.1% { transform: none; }
  72.2% { transform: scale(0) rotate(40deg); }
  100% { transform: scale(0) rotate(40deg); }
`;

const StyledLetter = styled.g`
  opacity: 0;
  animation: ${letterLeft} ${headerAnimationDuration}s 2s infinite;
`;

const StyledLine = styled.path`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${letterPathLength(950)} ${headerAnimationDuration}s 2s infinite;
`;

const StyledArrow = styled.path`
  transform-origin: 0px 0px;
  transform: scale(0) rotate(40deg);
  animation: ${arrow} ${headerAnimationDuration}s 2.2s infinite;
`;

const LetterLeft = () => (
  <svg viewBox="0 0 166 134" preserveAspectRatio="xMidYMid slice">
    <StyledLetter>
      <g transform="matrix(1,0,0,1,132.5,64.5)">
        <path fill="#f49386" fillOpacity="1" d=" M18.5,0 C18.5,0 17.84000015258789,12.5 17.84000015258789,12.5 C17.84000015258789,12.5 -18.5,6.199999809265137 -18.5,6.199999809265137 C-18.5,6.199999809265137 -16.600000381469727,-12.5 -16.600000381469727,-12.5 C-16.600000381469727,-12.5 18.5,0 18.5,0z" />
      </g>
      <g transform="matrix(1,0,0,1,133.5,59.5)">
        <path strokeLinejoin="round" fillOpacity="0" stroke="#761113" strokeOpacity="1" strokeWidth="0.25" d=" M-17.5,-8.5 C-17.5,-8.5 -5.090000152587891,3.1600000858306885 -5.090000152587891,3.1600000858306885 C-5.090000152587891,3.1600000858306885 0.6000000238418579,8.5 0.6000000238418579,8.5 C0.6000000238418579,8.5 17.5,4.389999866485596 17.5,4.389999866485596" />
      </g>
      <g transform="matrix(1,0,0,1,121,66.5)">
        <path strokeLinejoin="round" fillOpacity="0" stroke="#761113" strokeOpacity="1" strokeWidth="0.25" d=" M-7,3.5 C-7,3.5 7,-3.5 7,-3.5" />
      </g>
      <g transform="matrix(1,0,0,1,145,72)">
        <path strokeLinejoin="round" fillOpacity="0" stroke="#761113" strokeOpacity="1" strokeWidth="0.25" d=" M5,5 C5,5 -5,-5 -5,-5" />
      </g>
    </StyledLetter>
    <g transform="matrix(1,0,0,1,119.20700073242188,28.104000091552734)">
      <StyledLine strokeLinejoin="round" fillOpacity="0" stroke="#761113" strokeOpacity="1" strokeWidth="0.25" d=" M-18.2450008392334,-8.131999969482422 C-18.2450008392334,-8.131999969482422 18.2450008392334,8.128000259399414 18.2450008392334,8.128000259399414" />
    </g>
    <g transform="matrix(1,0,0,1,85.58300018310547,79.71399688720703)">
      <StyledLine strokeLinejoin="round" fillOpacity="0" stroke="#761113" strokeOpacity="1" strokeWidth="0.25" d=" M-23.756999969482422,-1.2979999780654907 C-23.756999969482422,-1.2979999780654907 23.753000259399414,1.3020000457763672 23.753000259399414,1.3020000457763672" />
    </g>
    <g transform="matrix(1,0,0,1,42.347999572753906,128.06500244140625)">
      <StyledLine strokeLinejoin="round" fillOpacity="0" stroke="#761113" strokeOpacity="1" strokeWidth="0.25" d=" M-21.43600082397461,5.61899995803833 C-21.43600082397461,5.61899995803833 21.43400001525879,-5.620999813079834 21.43400001525879,-5.620999813079834" />
    </g>
    <g transform="matrix(1,0,0,1,76.0530014038086,121.01000213623047)">
      <StyledArrow fill="#00a1d6" fillOpacity="1" d=" M-9.133999824523926,-5.313000202178955 C-9.133999824523926,-5.313000202178955 -5.354000091552734,5.316999912261963 -5.354000091552734,5.316999912261963 C-5.354000091552734,5.316999912261963 9.13599967956543,-4.0229997634887695 9.13599967956543,-4.0229997634887695 C9.13599967956543,-4.0229997634887695 -9.133999824523926,-5.313000202178955 -9.133999824523926,-5.313000202178955z" />
    </g>
  </svg>
);

export default LetterLeft;
