import React from "react";
import tw from "twin.macro";

import DetailsSmall from "@components/svg/details-small";
import DetailsLarge from "@components/svg/details-large";
import DetailsRedArrow from "@components/svg/details-red-arrow";

const BlueDotWithLine = tw.div`
  absolute hidden transform rotate-180 -scale-y-100
  md:block md:top-100 md:left-0 md:w-105
  lg:top-35 lg:w-160
  xl:top-50 xl:-left-80 xl:w-198
  xxl:-top-20 xxl:-left-140 xxl:w-220
`;

const RedArrowWithDetails = tw.div`
  absolute hidden transform -scale-x-100 -z-1
  lg:block lg:top-110 lg:left-285 lg:w-100
  xl:top-145 xl:w-150
`;

const ThreeDotsWithDetails = tw.div`
  absolute hidden transform -scale-x-100 -z-1
  md:block md:top-20 md:-right-120 md:w-190
  lg:top-40 lg:-right-60
  xl:top-45 xl:-right-90 xl:w-300
  xxl:top-50 xxl:-right-290 xxl:w-330
`;

const BackgroundDetails = () => (
  <>
    <BlueDotWithLine>
      <DetailsSmall />
    </BlueDotWithLine>
    <RedArrowWithDetails>
      <DetailsRedArrow />
    </RedArrowWithDetails>
    <ThreeDotsWithDetails>
      <DetailsLarge />
    </ThreeDotsWithDetails>
  </>
);

export default BackgroundDetails;
