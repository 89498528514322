import React from "react";
import { fullWidthBox } from "@utils/styled-components";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import tw from "twin.macro";

const GradientWrapper = tw(fullWidthBox)`h-0 mb-68 xl:mb-80`;

const GradientLinear = styled.div`
  background: linear-gradient(180deg, #FFFFFF 0%,
                                      #FFF5F5 230px,
                                      #FFF5F5 100%);
  //@todo modify height
  //xl:h-2560
  ${tw`
    absolute w-full h-2050 -top-328 -z-1
    sm:-top-100 sm:h-2258
    lg:h-2560
    xl:bottom-auto xl:-top-50
  `}

  ${up('lg')} {
    background: linear-gradient(180deg, #FFFFFF 0%,
                                        #FFF5F5 495px,
                                        #FFF5F5 100%);
  }
`;

const Gradient = () => (
  <GradientWrapper>
    <GradientLinear />
  </GradientWrapper>
);

export default Gradient;
